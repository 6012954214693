.datenschutz {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .datenschutz h1 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .datenschutz h2 {
    font-size: 1.5em;
    color: #444;
    margin-top: 30px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
  }
  
  .datenschutz p {
    margin-bottom: 15px;
  }
  
  .datenschutz ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
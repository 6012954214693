@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
.navbar-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.75rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.navbar-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 13rem;
}

@media screen and (max-width: 900px) {
    .navbar-wrapper{
        gap: 1rem;
        flex-direction: column;
        margin-bottom: .5rem;
    }
    .navbar-container{
        height: 8rem;
    }
}

.footer-links {
  margin-top: 15px;
  text-align: center;
}

.footer-link {
  color: #333;
  text-decoration: none;
  margin: 0 5px;
}

.footer-link:hover {
  text-decoration: underline;
}


.home-background{
    background-image: 
    linear-gradient(
      320deg in srgb, 
      #9D0AC6 0%, 31%, rgb(95% 28% 7%) 91% 91%
    )
  ;
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1000px) {
    .home-background{
        height: 500px;
    }
}
@media screen and (max-width: 800px) {
    .home-background{
        height: 800px;
    }
}



.home-content{
    display: flex;
    width: 65%;
    align-items: center;
    height: 90%;
}
@media screen and (max-width: 1300px) {
    .home-content{
        width: 90%;
    }
}
@media screen and (max-width: 800px) {
    .home-content{
        flex-direction: column;
        justify-content: center;
    }
}

.home-content-left{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: white;
    width: 55%;
    z-index: 2;
}

.home-content-left-h2{
    font-size: 2rem;
}

 @media screen and (max-width: 500px) {
        .home-content-p2{
           display: none;
        }
    }


@media screen and (max-width: 1300px) {
    .home-content-left-h2{
       font-size: 1.5rem
    }
    .home-content-left-p{
        font-size: 1rem;
    }
}
@media screen and (max-width: 800px) {
    .home-content-left-h2{
       font-size: 1.8rem
    }
    .home-content-left-p{
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 500px) {
    .home-content-left-h2{
       font-size: 1.4rem
    }
    .home-content-left-p{
        font-size: 1rem;
    }
}
@media screen and (max-width: 800px) {
    .home-content-left{
        width: 90%;
    }
}

.home-content-left-p{
    font-size: 1.2rem;
}

.home-btn{
    border-radius: 50px;
    background: #190020;
    white-space: nowrap;
    padding: 0.5rem 2rem;
    color: white;
    font-size: 1.3rem;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%); 
    display: flex;
    gap: 1rem;
}
@media screen and (max-width: 900px) {
    .home-btn{
        font-size: 1rem;
    }
}


.home-img{
    width:500px;
}
.home-img-wrapper{
    z-index: 3;
}
@media screen and (max-width: 1000px) {
    .home-img{
        width:400px;
    }
}
@media screen and (max-width: 1000px) {
    .home-img{
        width:320px;
    }
}
@media screen and (max-width: 400px) {
    .home-img{
        width:300px;
    }
}
@media screen and (max-width: 300px) {
    .home-img{
        width:250px;
    }
}


.navbar-btns{
    display: flex;
    gap:1rem
}

.divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}
@media screen and (max-width: 1300px) {
    .divider{
        display: none;
    }
}

.divider svg {
    position: relative;
    display: block;
    width: calc(156% + 1.3px);
    height: 347px;
}
.divider .shape-fill {
    fill: #FFFFFF;
}

.navbar-logo{
width: 130px;
}

.navbar-btn{
    background: white;
    border: 1.8px solid #190020;
    white-space: nowrap;
    padding: 0 0.5rem 0  1.75rem;
    color: black;
    font-size: .8rem;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%); 
    display: flex;
    gap: 1rem;
}

.navbar-btn2{
    background: white;
    border: 1.8px solid #190020;
    white-space: nowrap;
    padding: 0 0.5rem 0  1.75rem;
    color: black;
    font-size: .8rem;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%); 
    display: flex;
    gap: 1rem;
}
.navbar-btn-p{
    margin-left: 0.5rem
}

@media screen and (max-width: 500px) {
    .navbar-btn2{
        display: none;
    }   
    .navbar-btn{
        width: 90vw;
    }
    .navbar-btn-p{
        font-size: 1.2rem;
        margin: 0.75rem
    }
}



.about-section{
    display: flex;
    flex-direction: column;
    margin: 0 12rem 0 12rem;
}
@media screen and (max-width: 1455px) {
    .about-section{
        margin: 0 6rem 0 6rem;
    }
}
@media screen and (max-width: 1265px) {
    .about-section{
        margin: 0 1rem 0 1rem;
    }
}
.about-section-header-h2{
    font-size: 2.2rem;
    margin-bottom: 0;
}
@media screen and (max-width: 1630px) {
    .about-section-header-h2{
        font-size: 2rem;
    }
}
@media screen and (max-width: 1000px) {
    .about-section-header-h2{
        font-size: 1.5rem;
    }
}
.about-section-header-p{
    font-size: 1.7rem;
    margin-top: 0;
}
@media screen and (max-width: 1630px) {
    .about-section-header-p{
        font-size: 1.5rem;
    }
}
@media screen and (max-width: 1000px) {
    .about-section-header-p{
        font-size: 1.1rem;
    }
}

.about-section-header{
display: flex;
    }

.about-header-wrapper{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-left: 2rem;
}

@media screen and (max-width: 800px) {
    .about-header-wrapper{
        display: none;
    }
}

.about-header-logo1{
    width: 82px
}
.about-header-logo2{
    width: 82px;
    transform: translateY(42px);
    margin-left: .32rem
}
.about-header-logo3{
    width: 82px;
    transform: translateY(-35px);
    margin-left: .23rem
}

@media screen and (max-width: 800px) {
    .about-header-logo1{
        width: 65px
    }
    .about-header-logo2{
        width: 65px;
        transform: translateY(42px);
        margin-left: .32rem
    }
    .about-header-logo3{
        width: 65px;
        transform: translateY(-35px);
        margin-left: .23rem
    }
}

.about-text{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}
@media screen and (max-width: 800px) {
    .about-text{
    flex-direction: column;
    }
}

.about-text-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: .75rem;
}

@media screen and (max-width: 1630px) {
    .about-text-wrapper{
    gap: .5rem;
    }
}
@media screen and (max-width: 800px) {
    .about-text-wrapper{
        order: -1
    }
}

.about-text-img{
width:500px
}
@media screen and (max-width: 1102px) {
    .about-text-img{
    width:350px;
    }
}
@media screen and (max-width: 832px) {
    .about-text-img{
    width:300px;
    }
}
@media screen and (max-width: 300px) {
    .about-text-img{
    width:250px;
    }
}


.about-text-item{
    margin: 0;
    font-size: 1.7rem;
    text-transform: uppercase;
    display: flex;
    background-color: rgb(235, 235, 235);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 0.3rem;
    border-radius: 8px;
}

@media screen and (max-width: 1630px) {
    .about-text-item{
        font-size: 1.4rem;
    }
}
@media screen and (max-width: 1500px) {
    .about-text-item{
        font-size: 1.3rem;
    }
}
@media screen and (max-width: 955px) {
    .about-text-item{
        font-size: 1rem;
    }
}

.about-text-item-usw{
    margin: 0;
    font-size: 1rem;
    display: flex;
    padding: 0.3rem;
    font-size: 1.5rem;
}
@media screen and (max-width: 1000px) {
    .about-text-item-usw{
        font-size: 1.1rem;
    }
}

.about-text-item:nth-child(even){
   color:#d05706
}


.footer-text {
    display: flex;
    justify-items: center;
    align-items: center;
    width: fit-content;
    gap: 8rem;
    flex-wrap: wrap;
    padding: 1rem;
}
.footer{
    background-color: #a0a0a0;
    color: #495057;
    padding: 50px 0;
    font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  @media screen and (max-width: 380px) {
    
    .footer{
    }
}

  .footer-links{
      display: flex;
      flex-direction: column;
    }
    
    .col-1,
    .col-2,
    .col-3 {
        height: 130px;
        /* Add the following properties */
        display: flex;
        flex-direction: column;
    }
    
    .footer-p{
        display: flex;
        align-items: center;
    margin:0
}
@media screen and (max-width: 800px) {
    
    .footer-text {
        gap: 3rem;
        width:100%;
    }
}
    @media screen and (max-width: 800px) {
        .col-3{
            margin: auto;
        }
    }
    @media screen and (max-width: 800px) {
        .col-2{
            margin: auto;
        }
    }
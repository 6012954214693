:root {
  --font-base: "DM Sans", sans-serif;
  --primary-color: #ffffff;
  --secondary-color: #FAC189;
  --blue-color: #89C2FA;
  --black-color: #353531;
  --lightGray-color: #e4e4e4;
  --gray-color: #5F5E5E;
  --green-color: #E1E096;
  --white-color: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
